<template>
  <section class="order_type f">
    <div class="type_item frfsfs" :class="{'active': currentIndex === index}" v-for="(item, index) in orderType" :key="index" @click="chooseOrderType(index, item.value)">
      <div class="text">{{item.name}}</div>
      <div class="line" :style="{'display': index === orderType.length - 1 ? 'none' : 'block'}">|</div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        orderType: [
          {name: '全部订单', value: null},
          {name: '待付款', value: 0},
          {name: '待发货', value: 1},
          {name: '待收货', value: 2},
          {name: '已完成', value: 9}
        ],
        currentIndex: 0
      }
    },

    methods: {
      chooseOrderType(index, orderStatus) {
        this.currentIndex = index;
        this.$emit('chooseOrderType', orderStatus);
      }
    }
  }
</script>

<style lang="less" scoped>
  .order_type {
    margin-top: 15px;
    border-bottom: 1px solid var(--colorddd);
    .type_item {
      .text {
        margin: 0 15px;
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: 500;
        line-height: 16px;
        padding-bottom: 15px;
        border-bottom: 4px solid transparent;
        cursor: pointer;
      }
      .line {
        margin: 0 15px;
        font-size: var(--fontSize16);
        color: var(--colorccc);
        font-weight: 500;
        line-height: 16px;
      }
    }
    .active {
      .text {
        color: var(--colored6d00);
        font-weight: bold;
        border-bottom: 4px solid var(--colored6d00);
      }
    }
  }
</style>
