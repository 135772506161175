<template>
  <section class="personal_center">
    <NavBar />
    <Header type="personalCenter" />
    <div class="content box frfsfs pt15">
      <div class="menu mr30">
        <div class="title">
          当前位置：订单列表
        </div>
        <div class="menu_list mt15 p024">
          <div class="menu_item mt24" v-for="(item1, index1) in menuList" :key="index1">
            <div>{{item1.name}}</div>
            <p class="mt15" :class="{'avtive': currentIndex1 === index1 && currentIndex2 === index2}" v-for="(item2, index2) in item1.child" :key="index2" @click="chooseMenu(index1, index2)">{{item2.name}}</p>
          </div>
        </div>
      </div>
      <OrderList v-if="currentIndex1 === 0 && currentIndex2 === 0" />
      <InvoiceCenter v-if="currentIndex1 === 1 && currentIndex2 === 0" />
      <AddressManagement v-if="currentIndex1 === 1 && currentIndex2 === 1" />
    </div>
  </section>
</template>

<script>
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import OrderList from './childrenPage/orderList/OrderList.vue';
  import InvoiceCenter from './childrenPage/invoiceCenter/InvoiceCenter.vue';
  import AddressManagement from './childrenPage/addressManagement/AddressManagement.vue';

  export default {
    components: {
      NavBar,
      Header,
      OrderList,
      InvoiceCenter,
      AddressManagement
    },

    created() {
      if(this.$route.params.level1) {
        this.currentIndex1 = this.$route.params.level1;
        this.currentIndex2 = this.$route.params.level2;
      }
    },

    data() {
      return {
        menuList: [
          {
            name: '我的订单',
            child: [
              {name: '订单列表'}
            ]
          },
          {
            name: '账户设置',
            child: [
              {name: '发票中心'},
              {name: '地址管理'}
            ]
          }
        ],
        currentIndex1: 0,
        currentIndex2: 0
      }
    },

    methods: {
      chooseMenu(index1, index2) {
        this.currentIndex1 = index1;
        this.currentIndex2 = index2;
      }
    }
  }
</script>

<style lang="less" scoped>
  .personal_center {
    .content {
      .menu {
        .title {
          font-size: var(--fontSize16);
          color: var(--color000);
          font-weight: 400;
          line-height: 30px;
        }
        .menu_list {
          width: 150px;
          height: 200px;
          background: var(--colorfcfcfc);
          border: 1px solid var(--colore8e8e8);
          .menu_item {
            div {
              font-size: var(--fontSize16);
              color: var(--color000);
              font-weight: bold;
            }
            p {
              font-size: var(--fontSize14);
              color: var(--color333);
              font-weight: 500;
              text-align: center;
              cursor: pointer;
            }
            .avtive {
              color: var(--colored6d00);
            }
          }
        }
      }
    }
  }
</style>
