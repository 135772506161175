<template>
  <section class="address_management">
    <div class="header frfec">
      <div class="right" @click="createGoodsAddress">+ 新增收货地址</div>
    </div>
    <div class="address_list mt15">
      <div class="address_item mb15 p15" :class="{'active': currentIndex === index}" v-for="(item, index) in addressList" :key="item.id" @click="chooseAddress(index)">
        <div>
          收货地址：<span>{{`${item.province} ${item.city} ${item.area} ${item.addressDesc}`}}</span>
        </div>
        <div class="m150">
          收货姓名：<span>{{item.linkMan}}</span>
        </div>
        <div class="m150">
          联系电话：<span>{{item.linkTel}}</span>
        </div>
        <div class="frsbc">
          <div class="left">
            <div class="default p5" v-if="item.isDefault">默认</div>
            <el-checkbox @change="setDefault(item.id, item.isDefault)" v-else>设为默认</el-checkbox>
          </div>
          <div class="right f">
            <div @click="editGoodsAddress(item.id)">编辑</div>
            <div class="m05">|</div>
            <el-popconfirm title="确认要删除该地址吗？" confirm-button-text="确定" cancel-button-text="取消" @confirm="deleteGoodsAddress(item.id)">
              <div slot="reference">删除</div>
            </el-popconfirm>
          </div>
        </div>
      </div>
    </div>
    <div class="no_invoice" v-if="isNoAddress">
      <img src="~assets/images/no-address.png">
    </div>

    <CreateGoodsAddress ref="CreateGoodsAddress" :visible.sync="visible" :title="title" @determine="determineHandle" />
  </section>
</template>

<script>
  import CreateGoodsAddress from '../../../settlementPage/components/CreateGoodsAddress.vue';
  import {
    createGoodsAddress,
    getUserAddressList,
    deleteGoodsAddress
  } from 'api/settlementPage.js';

  export default {
    components: {
      CreateGoodsAddress
    },

    created() {
      this.getUserAddressList();
    },

    data() {
      return {
        visible: false,
        title: '',
        addressList: [],
        currentIndex: 0,
        isNoAddress: false
      }
    },

    methods: {
      getUserAddressList() {
        getUserAddressList().then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          if(res.data.length === 0) {
            this.isNoAddress = true;
          }else {
            this.isNoAddress = false;
          }
          this.addressList = res.data;
        });
      },
      createGoodsAddress() {
        this.visible = true;
        this.title = '新增收货地址';
      },
      editGoodsAddress(id) {
        this.$refs.CreateGoodsAddress.getGoodsAddress(id);
        this.visible = true;
        this.title = '编辑收货地址';
      },
      determineHandle() {
        this.currentIndex = 0;
        this.getUserAddressList();
      },
      deleteGoodsAddress(id) {
        deleteGoodsAddress({
          id
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.currentIndex = 0;
          this.getUserAddressList();
        });
      },
      setDefault(id, isDefault) {
        if(isDefault == 1) {
          return;
        }
        createGoodsAddress({
          id,
          isDefault: 1
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          this.$message.success(res.message);
          this.currentIndex = 0;
          this.getUserAddressList();
        });
      },
      chooseAddress(index) {
        this.currentIndex = index;
      }
    },

    watch: {
      addressList: {
        handler(newValue) {
          newValue.forEach((item, index) => {
            if(item.isDefault) this.currentIndex = index;
          });
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

<style lang="less" scoped>
  .address_management {
    width: 100%;
    .header {
      .right {
        font-size: var(--fontSize16);
        color: var(--color1890ff);
        font-weight: bold;
        line-height: 30px;
        cursor: pointer;
      }
    }
    .address_list {
      .address_item {
        background: var(--colorfbfbfb);
        border: 1px solid var(--colore6e6e6);
        cursor: pointer;
        div {
          font-size: var(--fontSize16);
          color: var(--color333);
          font-weight: 400;
          span {
            font-size: var(--fontSize18);
            color: var(--color000);
            font-weight: bold;
          }
          .left {
            .default {
              background: var(--colorfcef84);
              border-radius: 4px;
              font-size: var(--fontSize12);
              color: var(--color591d01);
              font-weight: bold;
            }
            .el-checkbox {
              color: var(--color999);
            }
          }
          .right {
            div {
              font-size: var(--fontSize12);
              color: var(--color1890ff);
              font-weight: 500;
            }
          }
        }
      }
      .active {
        background: var(--colorfffbf7);
        border: 1px solid var(--colored6d00);
        div {
          .right {
            div {
              color: var(--colored6d00);
            }
          }
        }
      }
    }
    .no_invoice {
      margin: 100px auto 0;
      width: 306px;
      height: 196px;
    }
  }
</style>
