<template>
  <section class="order_list">
    <div class="warning frfsc p030">
      <div class="left mr15">
        <img src="~assets/images/warning.png">
      </div>
      <div class="right">青葫芦会员商城小程序中的订单暂未在此展示。如需查看更多订单，请移步至小程序中查看。</div>
    </div>
    <OrderType @chooseOrderType="chooseOrderType" />
    <OrderList :orderList="orderList" />
    <div class="no_order" v-if="isNoOrder">
      <img src="~assets/images/no-order.png">
    </div>
    <Pagination :pageIndex.sync="paging.pageIndex" :pageSize.sync="paging.pageSize" :total="paging.total" @change="getOrderList" v-if="!isNoOrder" />
  </section>
</template>

<script>
  import OrderType from './components/OrderType.vue';
  import OrderList from './components/OrderList.vue';
  import Pagination from 'components/Pagination/index.vue';
  import {
    getOrderList
  } from 'api/personalCenter.js';
import { resourceUsage } from 'process';

  export default {
    components: {
      OrderType,
      OrderList,
      Pagination
    },

    created() {
      this.getOrderList();
    },

    data() {
      return {
        searchData: {
          status: null
        },
        paging: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        },
        orderList: [],
        isNoOrder: false
      }
    },

    methods: {
      getOrderList() {
        getOrderList({
          ...this.searchData,
          pageIndex: this.paging.pageIndex,
          pageSize: this.paging.pageSize
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          if(res.data.records.length === 0) {
            this.isNoOrder = true;
          }else {
            this.isNoOrder = false;
          }
          this.orderList = res.data.records;
          this.orderList.forEach(item => {
            if(item.status == 0) {
              let timeDifference = new Date(item.implementCloseTime).getTime() - new Date().getTime();
              this.$set(item, 'MSNumber', timeDifference);
            }
          });
          this.paging.total = res.data.total;
        });
      },
      chooseOrderType(orderStatus) {
        this.searchData.status = orderStatus;
        this.paging.pageIndex = 1;
        this.paging.pageSize = 10;
        this.getOrderList();
      }
    }
  }
</script>

<style lang="less" scoped>
  .order_list {
    flex: 1;
    .warning {
      width: 100%;
      height: 30px;
      background: var(--colorfffaec);
      .left {
        width: 20px;
        height: 20px;
      }
      .right {
        font-size: var(--fontSize12);
        color: var(--colored6d00);
        font-weight: 500;
      }
    }
    .no_order {
      margin: 100px auto 0;
      width: 306px;
      height: 196px;
    }
  }
</style>
