<template>
  <section class="invoice_center">
    <div class="header frsbc">
      <div class="left">请选择您要开票的订单</div>
      <div class="right" @click="invoiceHistory">开票历史</div>
    </div>
    <div class="invoice_list mt15">
      <div class="invoice_item p15 frfsc" v-for="item in invoiceList" :key="item.orderNo">
        <div class="select mr15">
          <el-checkbox v-model="item.isSelect"></el-checkbox>
        </div>
        <div class="order_info">
          <div>
            订单编号：<span>{{item.orderNo}}</span>
          </div>
          <div class="frsbc mt15">
            <div>
              订单时间：<span>{{item.createTime}}</span>
            </div>
            <div class="order_amount">
              订单金额：<span>{{item.canInvoiceMoney}}元</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no_invoice" v-if="isNoInvoice">
      <img src="~assets/images/no-invoice.png">
    </div>
    <Pagination :pageIndex.sync="paging.pageIndex" :pageSize.sync="paging.pageSize" :total="paging.total" @change="getInvoiceList" v-if="!isNoInvoice" />
    <div class="footer p030 frsbc">
      <div class="left frfsc">
        <div class="all_select">
          <el-checkbox v-model="allSelect">全选</el-checkbox>
        </div>
        <div class="selected ml15">
          已选订单<span class="ml5">{{selected}}个</span>
        </div>
      </div>
      <div class="right frfsc">
        <div class="invoice_total_amount">
          发票总额<span>￥{{invoiceTotalAmount.toFixed(2)}}</span>
        </div>
        <div class="next_step ml15" :class="{'no_select': selected === 0}" @click="nextStep">下一步</div>
      </div>
    </div>
  </section>
</template>

<script>
  import Pagination from 'components/Pagination/index.vue';
  import {
    getInvoiceList
  } from 'api/personalCenter.js';

  export default {
    components: {
      Pagination
    },

    created() {
      this.getInvoiceList();
    },

    data() {
      return {
        paging: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        },
        invoiceList: [],
        isNoInvoice: false
      }
    },

    computed: {
      allSelect() {
        return this.invoiceList.every(item => item.isSelect);
      },
      selected() {
        return this.invoiceList.filter(item => item.isSelect).length;
      },
      invoiceTotalAmount() {
        let arr = [];
        this.invoiceList.filter(item => item.isSelect).forEach(item => arr.push(item.canInvoiceMoney));
        return arr.reduce((a, b) => {
          return a + b;
        }, 0);
      }
    },

    methods: {
      invoiceHistory() {
        this.$router.push({
          name: 'invoiceHistory'
        });
      },
      getInvoiceList() {
        getInvoiceList({
          pageIndex: this.paging.pageIndex,
          pageSize: this.paging.pageSize
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          if(res.data.records.length === 0) {
            this.isNoInvoice = true;
          }else {
            this.isNoInvoice = false;
          }
          this.invoiceList = res.data.records;
          this.paging.total = res.data.total;
          this.invoiceList.forEach(item => {
            this.$set(item, 'isSelect', false);
          });
        });
      },
      nextStep() {
        if(this.selected === 0) {
          this.$message.warning('请选择需要开票的订单');
          return;
        }
        this.$router.push({
          name: 'openInvoice',
          params: {
            invoiceTotalAmount: this.invoiceTotalAmount,
            emInvoiceDetails: this.invoiceList.filter(item => item.isSelect)
          }
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .invoice_center {
    width: 100%;
    .header {
      line-height: 30px;
      .left {
        font-size: var(--fontSize16);
        color: var(--color000);
        font-weight: bold;
      }
      .right {
        font-size: var(--fontSize14);
        color: var(--color1890ff);
        font-weight: bold;
        cursor: pointer;
      }
    }
    .invoice_list {
      .invoice_item {
        width: 100%;
        height: 120px;
        border: 1px solid var(--colore6e6e6);
        .select {
          
        }
        .order_info {
          width: 100%;
          div {
            font-size: var(--fontSize16);
            color: var(--color333);
            font-weight: 400;
            span {
              font-size: var(--fontSize16);
              color: var(--color000);
            }
          }
          .order_amount {
            span {
              color: var(--colorfe1818);
            }
          }
        }
      }
    }
    .no_invoice {
      margin: 100px auto 0;
      width: 306px;
      height: 196px;
    }
    .footer {
      position: fixed;
      left: 50%;
      bottom: 50px;
      transform: translateX(-50%);
      z-index: 100;
      width: 1200px;
      height: 100px;
      background: var(--colorfff);
      border: 1px solid var(--colorececec);
      border-top: 4px solid var(--colored6d00);
      box-shadow: 0px 8px 9px 0px var(--colorf1f1f1);
      .left {
        .all_select {
          .el-checkbox {
            font-size: var(--fontSize20);
            color: var(--color000);
            font-weight: 500;
          }
        }
        .selected {
          font-size: var(--fontSize12);
          color: var(--color7c8b99);
          font-weight: 500;
          span {
            font-size: var(--fontSize16);
            color: var(--color000);
            font-weight: bold;
          }
        }
      }
      .right {
        .invoice_total_amount {
          font-size: var(--fontSize12);
          color: var(--color7c8b99);
          font-weight: 500;
          span {
            font-size: var(--fontSize20);
            color: var(--colorfe1818);
            font-weight: bold;
          }
        }
        .next_step {
          width: 180px;
          height: 60px;
          background: var(--colored6d00);
          font-size: var(--fontSize20);
          color: var(--colorfff);
          font-weight: bold;
          text-align: center;
          line-height: 60px;
          cursor: pointer;
        }
        .no_select {
          background: var(--colorccc);
          color: var(--colorfff);
        }
      }
    }
  }
</style>
