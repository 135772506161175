<template>
  <section class="order_list mt30">
    <OrderItem v-for="item in orderList" :key="item.orderNo" :orderData="item" />
  </section>
</template>

<script>
  import OrderItem from './OrderItem.vue';

  export default {
    components: {
      OrderItem
    },

    props: {
      orderList: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="less" scoped>
  .order_list {

  }
</style>
