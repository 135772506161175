<template>
  <section class="order_item mb15">
    <div class="header frfsc">
      <div class="m015">
        订单号：<span>{{orderData.orderNo}}</span>
      </div>
      <div class="m015">
        <span>{{orderData.createTime}}</span>
      </div>
      <div class="m015">
        商品数量：<span>{{orderData.species}}品 / {{orderData.num}}件</span>
      </div>
      <div class="m015">
        订单金额：<span>{{orderData.totalMoney}}元</span>
      </div>
    </div>
    <div class="body frfsc">
      <div class="left f p015">
        <div class="mr15" v-for="(item, index) in orderData.booksPics.length > 6 ? orderData.booksPics.slice(0, 6) : orderData.booksPics" :key="index">
          <img :src="item">
        </div>
        <div v-if="orderData.booksPics.length > 6">
          <img src="~assets/images/more.png">
        </div>
      </div>
      <div class="middle fccc">
        <div class="order_status" v-if="orderData.canceled">已取消</div>
        <div class="order_status" v-else>{{orderData.status | orderStatus}}</div>
        <div class="order_detail mt5" @click="goOrderDetail">订单详情</div>
      </div>
      <div class="right fccc">
        <div :class="orderData.status == 0 ? 'go_pay' : 'again_procurement'" @click="goPay(orderData.orderNo, orderData.status)">{{orderData.status == 0 ? '去支付' : '再次采购'}}</div>
        <div class="see_logistics mt5" @click="seeLogistics" v-if="orderData.status == 0 || orderData.status == 2">{{orderData.status == 0 ? payCountdown : '查看物流'}}</div>
      </div>
    </div>
  </section>
</template>

<script>
  import {Mixin} from 'utils/mixins.js';
  import {
    buy
  } from 'api/personalCenter.js';

  export default {
    mixins: [Mixin],

    props: {
      orderData: {
        type: Object,
        default: () => {}
      }
    },

    mounted() {
      setInterval(() => {
        this.orderData.MSNumber -= 1000;
      }, 1000);
    },

    computed: {
      payCountdown() {
        let hours = Math.floor(this.orderData.MSNumber / 3600000);
        let minutes = Math.floor(this.orderData.MSNumber % 3600000 / 60000);
        let seconds = Math.floor(this.orderData.MSNumber % 3600000 % 60000 / 1000);
        return `${hours}时${minutes}分${seconds}秒`;
      }
    },

    methods: {
      goOrderDetail() {
        this.$router.push({
          name: 'orderDetail',
          params: {
            orderNo: this.orderData.orderNo
          }
        });
      },
      goPay(orderNo, status) {
        if(status == 0) {
          this.$router.push({
            name: 'payPage',
            params: {
              orderNo
            }
          });
        }else {
          buy({
            orderNo
          }).then(res => {
            if(res.code) {
              this.$message.error(res.message);
              return;
            }
            this.$router.push({
              name: 'settlementPage'
            });
          });
        }
      },
      seeLogistics() {
        this.$router.push({
          name: 'seeLogistics',
          params: {
            orderNo: this.orderData.orderNo,
            orderType: this.orderData.orderType
          }
        });
      }
    }
  }
</script>

<style lang="less" scoped>
  .order_item {
    width: 100%;
    height: 161px;
    background: var(--colorfff);
    border: 1px solid var(--colore8e8e8);
    .header {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid var(--colore8e8e8);
      background: var(--colorfbfbfb);
      div {
        font-size: var(--fontSize12);
        color: var(--color999);
        font-weight: 500;
        span {
          color: var(--color000);
        }
      }
    }
    .body {
      height: calc(100% - 40px);
      .left {
        flex: 1;
        div {
          width: 80px;
          height: 80px;
        }
      }
      .middle {
        width: 123px;
        height: 100%;
        border-left: 1px solid var(--colore8e8e8);
        border-right: 1px solid var(--colore8e8e8);
        .order_status {
          font-size: var(--fontSize16);
          color: var(--colored6d00);
          font-weight: bold;
        }
        .order_detail {
          border-bottom: 1px solid var(--color333);
          padding-bottom: 1px;
          font-size: var(--fontSize12);
          color: var(--color333);
          font-weight: 500;
          cursor: pointer;
        }
      }
      .right {
        width: 169px;
        height: 100%;
        .go_pay {
          width: 90px;
          height: 30px;
          background: var(--colored6d00);
          font-size: var(--fontSize16);
          color: var(--colorfff);
          font-weight: bold;
          text-align: center;
          line-height: 28px;
          cursor: pointer;
        }
        .again_procurement {
          width: 90px;
          height: 30px;
          border: 1px solid var(--colored6d00);
          font-size: var(--fontSize16);
          color: var(--colored6d00);
          font-weight: bold;
          text-align: center;
          line-height: 28px;
          cursor: pointer;
        }
        .see_logistics {
          font-size: var(--fontSize12);
          color: var(--color1890ff);
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
</style>
