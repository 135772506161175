let Mixin = {
  filters: {
    orderStatus(status) {
      switch(status) {
        case -1:
          return '交易关闭';
        case 0:
          return '待付款';
        case 1:
          return '待发货';
        case 2:
          return '待收货';
        case 9:
          return '已完成';
      }
    }
  }
}

export {Mixin};
